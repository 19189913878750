@mixin layout($options: ()) {
  $configuration: map-merge(
    (
      menu-background-color: #262e41,
      menu-background-color-light: #1a2031,
      color: #27d6e6,
      second-color: #f5f5f5,
      second-border-color: #e5e5e5,
    ),
    $options
  );
  .hamburger-menu,
  #drawer {
    background-color: map-get($configuration, menu-background-color);
    a {
      color: map-get($configuration, second-color);
    }
  }
  a.nav-item:hover {
    background-color: map-get(
      $configuration,
      menu-background-color-light
    ) !important;
    color: map-get($configuration, color) !important;
    border-left: 5px solid map-get($configuration, menu-background-color-light);
  }
  a.nav-item.is-active:hover {
    background-color: map-get(
      $configuration,
      menu-background-color-light
    ) !important;
    border-left: 5px solid map-get($configuration, color) !important;
  }
  a.nav-item.is-active,
  a.nav-item.is-active:hover {
    background-color: map-get(
      $configuration,
      menu-background-color-light
    ) !important;
    color: #fff !important;
    border-left: 5px solid map-get($configuration, color) !important;
  }
  .nav-item {
    border-left: 5px solid map-get($configuration, menu-background-color);
  }
  .submenu {
    a.nav-item.is-active,
    a.nav-item.is-active:hover {
      background-color: map-get(
        $configuration,
        menu-background-color-light
      ) !important;
      color: map-get($configuration, color) !important;
    }
    a.nav-item,
    a.nav-item:hover,
    a.nav-item.is-active,
    a.nav-item.is-active:hover {
      border-left: 5px solid map-get($configuration, color) !important;
    }
  }
  /* * Logo */
  .logo_block {
    background: map-get($configuration, menu-background-color);
    @media only screen and (max-width: 768px) {
      border-right: solid 1px map-get($configuration, second-border-color);
    }
  }
  .badge {
    background: map-get($configuration, color);
  }
}
