input:placeholder-shown + label {
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

input + label {
  position: absolute;
  top: -57px;
  left: 38%;
  transition: all 0.1s;
  opacity: 1;
  background: #fff;
  border: none;
  padding: 5px 7px;
  -webkit-transform: translateY(calc(50% + 5px));
  transform: translateY(calc(50% + 5px));

  @media only screen and (max-width: 480px) {
    left: 25%;
  }
}

input:focus {
  outline: 0;
  border-color: $spaceti-color;
}

input:focus + label {
  border-color: $spaceti-color;
}

.calendar-input {
  &:hover {
    .calendar_label {
      display: block;
    }
  }

  .calendar_label {
    display: none;
    position: absolute;
    z-index: 999;
    background: $medium-transparent-background;
    padding: 10px 20px;
    margin-top: -60px;
    margin-left: 150px;
  }
}

kendo-multiselect {
  width: 100%;

  ::ng-deep .k-multiselect-wrap .k-searchbar {
    width: 100%;
  }
}

.input-search {
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid $second-border-color;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: $grey-text-color;
  padding-left: 37px;

  &:focus,
  &:hover {
    border: 1px solid $second-border-color;
  }
}

input::-webkit-input-placeholder {
  color: #dfe2e5;
  font-size: 16px;
}

input:-ms-input-placeholder {
  color: #dfe2e5;
  font-size: 16px;
}

input::-ms-input-placeholder {
  color: #dfe2e5;
  font-size: 16px;
}

input::placeholder {
  color: #dfe2e5;
  font-size: 16px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  background: #f2f3f7;
  border: 1px solid #e5e8f2;
  border-radius: 2px;
  padding: 7px;
  display: inline-block;
  position: relative;
  top: 2px;
  cursor: pointer;
}

input[type='checkbox']:checked {
  background: $spaceti-color;
  border-color: $spaceti-color;
}

input[type='checkbox']:checked:after {
  content: '\2714';
  font-size: 11px;
  position: absolute;
  top: -1px;
  left: 3px;
  color: white;
}

.input-search-icon {
  position: absolute;
  top: 11px;
  padding-left: 10px;
}
