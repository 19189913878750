.button,
.k-button {
  background-color: transparent;
  background: transparent;
  border-radius: 4px;
  border-color: transparent;
  outline: none;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;

  &:hover {
    border-color: transparent !important;
  }

  &.primary-button {
    min-width: 100px;
    color: $background-white-color !important;
    background-color: $spaceti-color !important;
    border-color: $spaceti-color !important;

    &:hover {
      background: darken($spaceti-color, 10%) !important;
      border-color: darken($spaceti-color, 10%) !important;
    }
  }

  &.secondary-button {
    min-width: 100px;
    color: $black-color;
    background-color: $light-grey-color;
    border-color: $light-grey-color;

    &:hover {
      transition-duration: 0.5s;
      background-color: darken($light-grey-color, 10%);
      border-color: darken($light-grey-color, 10%) !important;
    }
  }

  &.destroy-button {
    min-width: 100px;
    color: $background-white-color;
    background-color: $red-button-color;
    border-color: $red-button-color;

    &:hover {
      transition-duration: 0.5s;
      background-color: darken($red-button-color, 10%);
      border-color: darken($red-button-color, 10%) !important;
      background-image: none;
    }
  }
}

.button:focus:not(:active),
.button.is-focused:not(:active) {
  box-shadow: none !important;
}

.button:active,
.button.is-active,
.button:focus,
.button.is-focused {
  box-shadow: none;
}

.k-button:active,
.k-button.is-active,
.k-button:focus,
.k-button.is-focused {
  box-shadow: none;
  border: none;
}

.delete_button_secondary {
  color: $destroy-color;
  border: none;
}

.save_button_secondary {
  color: $spaceti-color;
  border: none;
}

.button-accept {
  background: $button-accept !important;
  border-color: $button-accept !important;
  color: white !important;
}

/*
* Buttons in tabs
*/

.tabs {
  .k-button,
  .button {
    padding-right: 5px;
  }
}

/*
* Action buttons
*/

.button-transparent {
  background-color: transparent !important;
  color: $main-color !important;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: transparent !important;
    color: $main-color !important;
    border-color: transparent !important;
  }
}

.button-transparent:active,
.button-transparent:focus {
  background-color: transparent !important;
}

.button-success:active,
.button-success:focus {
  background-color: $success-color !important;
}

.k-button.k-primary {
  background: $success-color !important;
  color: $second-color !important;
  border-color: $success-color !important;
  min-width: 100px;

  &:hover {
    background-color: $success-hover-color !important;
    color: $second-color !important;
    border-color: $success-hover-color !important;
  }
}

.button-destroy {
  background-color: $destroy-color !important;
  color: $second-color !important;
  border-color: $destroy-color !important;
  min-width: 100px;

  &:hover,
  &:focus {
    background-color: $destroy-hover-color !important;
    color: $second-color !important;
    border-color: $destroy-hover-color !important;
  }
}

.button-deactivate {
  color: $destroy-color;
  border-right: 1px solid $spaceti-color;
  border-radius: 0;
}

/*
* Buttons in nav
*/

button.nav-item {
  padding: 10px 7px;
}

/*
* Editor buttons
*/

.next_btn {
  background: $spaceti-color;

  &:disabled,
  button[disabled] {
    background: transparent;
  }
}

.previous_btn {
  border: 2px solid $spaceti-color;
}

.next_btn,
.previous_btn,
.next_btn:hover,
.previous_btn:hover,
.next_btn:focus,
.previous_btn:focus,
.next_btn:active,
.previous_btn:active {
  background: transparent;
  border: 2px solid $spaceti-color;
}

.close_btn {
  background-color: #000000;
  border: 2px solid #000000;
}

.grid-button {
  vertical-align: middle;
  margin: 0 0.16em;
  background-clip: padding-box;
  padding: 4px 8px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  background-image: linear-gradient(#f6f6f6, #f1f1f1);

  &:hover,
  &:focus,
  &:active {
    background: transparent;
    border-color: transparent;
    background-image: linear-gradient(#f6f6f6, #f1f1f1);
  }
}
