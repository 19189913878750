@import 'vendors/material';

// Bulma
@import '"~bulma/sass/utilities/all';
@import '"~bulma/sass/base/minireset';
@import '"~bulma/sass/base/generic';
@import '"~bulma/sass/base/helpers';
@import '"~bulma/sass/elements/title';
@import '"~bulma/sass/elements/button';
@import '"~bulma/sass/elements/container';
@import '"~bulma/sass/grid/all';
@import '"~bulma/sass/form/all';

// Fonts
@import 'fonts/opensans-bold';
@import 'fonts/opensans-regular';
@import 'fonts/opensans-extrabold';

/** Abstracts */
@import 'abstracts/variables';
@import 'abstracts/mixins';

/** Base */
@import 'base/reset';
@import 'base/typography';
@import 'base/helpers';

/** Layouts */
@import 'layouts/common';
@import 'layouts/grid';

/** Components */
@import 'components/inputs';
@import 'components/buttons';
@import 'components/aside_menu';
@import 'components/card';
@import 'components/dialog';
@import 'components/table';
@import 'components/tabs';
@import 'components/tooltip';
@import 'components/progress_circle';
@import 'components/loader';
@import 'components/map';
@import 'components/charts';
@import 'components/icons';
@import 'components/material_icons';

/** New Components **/
@import 'base/fonts';
@import 'module/button';
@import 'module/chip';

// Module thmes
@import '../../../../libs/core/initial/src/scss/theming';
@include account(
  (
    title-color: $spaceti-color,
    background-color: #f6f6f6,
  )
);
@import '../../../../libs/core/dashboard/src/scss/theming';
@include layout(
  (
    menu-background-color: #262e41,
    menu-background-color-light: #1a2031,
    color: #27d6e6,
    second-color: #f5f5f5,
    second-border-color: #e5e5e5,
  )
);
@import '../../../../libs/shared/ui/src/scss/theming';
@include shared(
  (
    spaceti-color: $spaceti-color,
    button-color: $button-color,
    grey-text-color: $grey-text-color,
    destroy-color: $destroy-color,
    success-color: $success-color,
    success-hover-color: $success-hover-color,
    second-color: $second-color,
    brand-color: $brand-color,
    white-background-color: white,
  )
);
