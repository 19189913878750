$button-primary-text: #ffffff;
$button-primary-background: #26d6e6;
$button-primary-hover-background: #76e6f2;
$button-primary-disabled-background: #76e6f2;
$button-primary-destructive-background: #dc3412;
$button-primary-destructive-hover-background: #e77159;
$button-primary-destructive-disabled-background: #ee9a89;

$button-secondary-text: #0b1c37;
$button-secondary-background: #f2f3f7;
$button-secondary-hover-background: #e5e8f2;
$button-secondary-disabled-background: #f2f3f7;
$button-secondary-destructive-background: #ffffff;

button.spa-button,
.spa-button {
  width: 100%;
  padding: 10px 12px;

  background-color: $button-primary-background;
  border: 1px solid;
  border-color: $button-primary-background;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: auto 60%;
  background-position: center center;

  font-family: 'Inter';
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: $button-primary-text;
  cursor: pointer;

  transition: 200ms all;

  &:hover {
    background-color: $button-primary-hover-background;
    border-color: $button-primary-hover-background;
  }

  &:disabled {
    background-color: $button-primary-disabled-background;
    border-color: $button-primary-disabled-background;
    cursor: not-allowed;
  }

  &.destructive {
    background-color: $button-primary-destructive-background;
    border-color: $button-primary-destructive-background;

    &:hover {
      background-color: $button-primary-destructive-hover-background;
      border-color: $button-primary-destructive-hover-background;
    }

    &:disabled {
      background-color: $button-primary-destructive-disabled-background;
      border-color: $button-primary-destructive-disabled-background;
      cursor: not-allowed;
    }
  }

  &.loading {
    background-image: url(./../../images/loader/loader-light.svg);
    color: transparent;
  }

  &.secondary {
    background-color: $button-secondary-background;
    border: 1px solid;
    border-color: $button-secondary-background;
    color: $button-secondary-text;

    &:hover {
      background-color: $button-secondary-hover-background;
      border-color: $button-secondary-hover-background;
    }

    &:disabled {
      background-color: $button-secondary-disabled-background;
      border-color: $button-secondary-hover-background;
    }

    &.destructive {
      color: $button-primary-destructive-background;
      background-color: $button-secondary-destructive-background;
      border-color: $button-primary-destructive-background;

      &:hover {
        color: $button-primary-destructive-hover-background;
        background-color: $button-secondary-destructive-background;
        border-color: $button-primary-destructive-hover-background;
      }

      &:disabled {
        color: $button-primary-destructive-disabled-background;
        background-color: $button-secondary-destructive-background;
        border-color: $button-primary-destructive-disabled-background;
        cursor: not-allowed;
      }
    }

    &.loading {
      background-image: url(./../../images/loader/loader-dark.svg);
      color: transparent !important;
    }
  }

  &.more {
    height: 37px;
    width: 37px;
    border-color: transparent;
    background-color: transparent;
    background-image: url(../../images/icons/20/More.svg);
    background-position: center center;
    background-size: 20px 20px;

    &:hover {
      background-color: $button-secondary-hover-background;
      border-color: $button-secondary-hover-background;
    }

    &:disabled {
      display: none;
    }
  }
}
