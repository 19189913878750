body,
html {
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $main-color;
  background-color: white;
  font-family: 'opensans-bold', sans-serif !important;
  font-display: fallback;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

body {
  background: white;
}

html {
  font-size: 100%;
}

div {
  font-family: 'opensans-regular', sans-serif !important;
  font-display: fallback;
}

img {
  max-width: 100%;
}

textarea {
  resize: none;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.error {
  color: $error-color;
}
