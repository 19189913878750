@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter400Base.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 400;
  unicode-range: U+0020-007F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter400Ext.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 400;
  unicode-range: U+00A0-00FF, U+0100-017F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter500Base.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 500;
  unicode-range: U+0020-007F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter500Ext.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 500;
  unicode-range: U+00A0-00FF, U+0100-017F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter600Base.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 600;
  unicode-range: U+0020-007F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter600Ext.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 600;
  unicode-range: U+00A0-00FF, U+0100-017F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter700Base.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 700;
  unicode-range: U+0020-007F;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter700Ext.woff2') format('woff2'); /* Modern Browsers */
  font-weight: 700;
  unicode-range: U+00A0-00FF, U+0100-017F;
  font-display: swap;
}
