// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dashboard-primary: mat.define-palette(mat.$indigo-palette);
$dashboard-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dashboard-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$dashboard-theme: mat.define-light-theme(
  $dashboard-primary,
  $dashboard-accent,
  $dashboard-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dashboard-theme);

.mat-menu-panel {
  border-radius: 8px !important;
  min-height: 48px !important;
}

.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.mat-ripple-element {
  transition-duration: 0ms !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #27d6e6 !important; // Override material
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #27d6e6 !important; // Override material
  z-index: 3;
}

.mat-radio-button .mat-radio-outer-circle {
  border-color: #999999 !important; // Override material
  z-index: 3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #27d6e6 !important; /*outer ring color change*/
}

.cdk-overlay-container {
  z-index: 9999;
}

.mat-calendar-body-selected {
  background-color: #27d6e6 !important;
}

.mat-calendar-table-header th {
  text-align: center !important;
}

.mat-form-field-infix {
  border-top: 4px solid transparent !important;
}
