.tooltip {
  .tooltiptext {
    display: none;
    min-width: 120px;
    background-color: black;
    position: absolute;
    z-index: 999;
    color: #fff;
    text-align: center;
    padding: 5px 7px;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 1s;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }
}

.tooltip:hover {
  .tooltiptext {
    display: inherit;
    opacity: 1;
  }
}

.big-tooltip {
  .tooltiptext {
    display: none;
    min-width: 250px;
    max-width: 360px;
    background-color: white;
    position: absolute;
    z-index: 999;
    color: $brand-color !important;
    padding: 23px 22px 23px 21px;
    opacity: 0;
    transition: opacity 1s;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-weight: normal;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: normal;
    margin-left: -170px;
  }
}

.big-tooltip:hover {
  .tooltiptext {
    display: inherit;
    opacity: 1;
    z-index: 9999999999;
  }
}

.link-tooltip {
  position: relative;
  display: inline-block;
}

.link-tooltip .link-tooltip__text {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.95);
  color: #fff !important;
  padding: 13px 0;
  position: absolute !important;
  z-index: 999;
  top: 150%;
  left: 50% !important;
  margin-left: -32px;
  width: 64px !important;
  min-height: 46px;
  font-size: 14px !important;
  font-size: 0.8vw !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-radius: 2px;
}

.link-tooltip .link-tooltip__text::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.85) transparent;
}

.link-tooltip:hover .link-tooltip__text {
  visibility: visible !important;
}
