.k-grid button {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  min-width: 80px;
  min-height: 36px;
  background: $button-color;
  color: $grey-text-color;

  &:hover,
  &:focus {
    background: $button-color;
    color: $grey-text-color;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.1);
  }

  &.button-destroy {
    background: transparent;
    color: $destroy-color;
  }

  &.button-success {
    background: $success-color;
    color: $second-color;

    &:hover,
    &:focus {
      background: $success-hover-color !important;
      color: $second-color !important;
      border-color: $success-hover-color;
    }
  }
}

.k-grid button.button-destroy {
  background: transparent;
  color: $destroy-color;
  border: none;

  &:hover,
  &:focus {
    background: transparent !important;
    color: $destroy-color !important;
    box-shadow: none;
  }
}

.k-grid .grid-btn {
  text-align: right;
}

.k-grid section img {
  max-width: 45px;
  vertical-align: middle;
}

.k-grid .cell-paragraph {
  color: $brand-color;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid tr.k-alt {
  background-color: $white-background-color !important;
}

.k-grid td {
  border-width: 1px 0 0 0 !important;
  color: $brand-color;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid th {
  border-width: 0 0 0 0;
}

.k-grid .k-pager-wrap {
  background-color: white !important;
}

.k-grid .k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: $brand-color;
}

.k-grid .k-state-selected {
  background-color: $spaceti-color !important;
  color: $second-color !important;
}

.k-grid {
  .k-grid-header .k-i-sort-asc-sm,
  .k-grid-header .k-i-sort-desc-sm,
  .k-grid-header .k-sort-order {
    color: $grey-text-color;
  }
}

.k-grid .k-grid-header a,
.k-grid .k-grid-header th {
  font-weight: 600 !important;
}

.k-grid .k-grid-header th {
  padding-bottom: 11px;
}

.k-grid .k-grid-header a {
  padding-top: 11px;
  outline: none;
}

.k-grid .k-grid-header th {
  background-color: white;
  color: $grey-text-color;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.k-grid tr {
  background-color: white;
}

.grid-btn {
  .s-icon {
    cursor: pointer;
  }
}

.k-grid .k-filtercell .k-filtercell-operator,
.k-grid .k-filtercell > span .k-button,
.k-grid .k-filtercell > span .k-dropdown-operator {
  display: none;
}

.k-grid.k-widget {
  border-color: #dfe2e5;
}

.k-grid {
  display: flex !important;
}
