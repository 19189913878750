@import '~apps/dashboard/src/styles/abstracts/variables';

mgl-map {
  .mapboxgl-ctrl-bottom-left {
    left: 36px;
  }
  .mapboxgl-ctrl-top-right {
    position: absolute;
    top: 5px;
    left: 15px;
  }
  .mapboxgl-ctrl-group {
    box-shadow: none !important;
    outline: 0;

    button {
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      border-radius: 4px !important;
      background-color: $background-white-color;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.13);

      &:focus,
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.13);
        border: 1px solid $spaceti-color;
        background-color: $background-blue !important;
        outline: 0;
      }
    }

    .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
      background-image: url(../../assets/images/map-ui/minus-big.svg) !important; 
    }

    .mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
      background-image: url(../../assets/images/map-ui/plus-big.svg) !important;
    }

    .mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
      background-image: url(../../assets/images/map-ui/zoom-out.svg) !important;
    }

    .mapboxgl-ctrl-traffic {
      display: none !important;
    }
  }

  .mapboxgl-ctrl {
    .mapboxgl-ctrl-search {
      background-image: url(../../assets/images/map-ui/search.svg);
    }
    .mapboxgl-ctrl-filters {
      mask-image: url(../../assets/images/map-ui/layers.svg);
    }
  }
  .mapboxgl-ctrl-compass {
    display: none !important;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
  }

  .mapboxgl-popup {
    max-width: 280px !important;
    pointer-events: none;

    .mapboxgl-popup-content {
      width: 280px;
      padding: 5px 10px;
      border-radius: 8px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
      pointer-events: none;

      .mapboxgl-popup-close-button {
        display: none;
      }
    }
  }
}
