$chip-background: #e5e8f2;
$chip-color: #0b1c37;

.spa-chip {
  display: inline-block;
  padding: 2px 5px;
  background-color: $chip-background;
  border-radius: 10px;

  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $chip-color;

  &.blue {
    background-color: #eef6ff;
    color: #2077cc;
  }

  &.green {
    background-color: #edfaec;
    color: #009e54;
  }

  &.yellow {
    background-color: #f3aa1a1a;
    color: #d8ac36;
  }

   &.red {
    
    background-color: #BD2B2B1A;
    color: #BD2B2B;
   }
}
