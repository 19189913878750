@mixin account($options: ()) {
  $configuration: map-merge(
    (
      title-color: black,
      background-color: #f6f6f6,
    ),
    $options
  );
  spa-login {
    background: map-get($configuration, background-color);
  }
  .my-title {
    a {
      color: map-get($configuration, title-color);
    }
  }
}
