.drawer {
  border: none;
  top: 64px;
  height: auto;
  min-height: 250px;
  background: transparent;
  position: fixed;

  @media screen and (min-width: 1024px) {
    top: 0;
  }

  @media only screen and (max-width: 1024px) {
    left: 0 !important;
  }
}

.aside-drawer {
  box-shadow: none;
  width: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  left: 0;
  box-sizing: border-box;
  color: #424242;
  overflow: visible;
  overflow-y: auto;
  z-index: 5;
  overflow: auto;

  .k-button,
  .button {
    border-radius: 50%;
  }
}

/*
* Aside menu - right
 */

.right_aside {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  max-height: 100%;
  top: 20px;
  right: 0;
  box-sizing: border-box;
  color: $brand-color;
  overflow: scroll;
  position: fixed;
  z-index: 10;
  background-color: $white-background-color;
  overflow-x: hidden;
  padding-top: 72px;
  width: 320px !important;

  li {
    .k-button,
    .button {
      margin-top: 13px;
    }
  }

  .menu {
    position: initial;
  }

  img {
    margin-right: 10px;
    width: 50px;
  }

  a {
    padding: 10px 10px 10px 30px;
    text-decoration: none;
    color: $brand-color;
    cursor: pointer;
    transition: color 0.4s ease-out;
    display: inline-block;
    width: 220px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  a:hover,
  .offcanvas a:focus {
    color: $link-hover-color;
  }

  .closebtn {
    position: fixed;
    top: 40px;
    right: 5px;
    font-size: 36px;
    cursor: pointer;
    text-align: right;
    width: auto !important;
    z-index: 9999;
  }
}

.menu-list a:hover {
  background-color: transparent;
}
