/* Main Colors */
$second-color: #f5f5f5;
$description-color: #7a7a7a;
$main-color: #262e42;
$brand-color: #262e42;
$text-color: #515868;
$headings-color: #262e42;
$light-color: #959ca4;
$dark-text-color: #262e41;
$grey-text-color: #979ba4;
$close-icon-color: #636e7d;
$error-color: #fb6d6d;
$input-color: #354052;

/** Borders colors */
$border-color: #efefef;
$input-border-color: #dfe2e5;
$second-border-color: #e5e5e5;

/** Shadows */
$box-shadow-color: #f7f7f7;

/* Spaceti colors */
$spaceti-color: #27d6e6;

/* Background colors */
$left-menu-bg: #262e41;
$left-menu-light-bg: #1a2031;
$bg-color: #212121;
$light-bg-color: #505050;
$white-background-color: white;
$dark-blue-bg-color: #131927;
$medium-transparent-background: hsla(0, 0%, 100%, 0.9);
$black-transparent-background: rgba(0, 0, 0, 0.9);
$light-background: #eaf8ff;
$dark-bg: #262e41;
$dark-blue-bg: #161b26;

/* Hover efect colors */
$link-hover-color: #27d6e6;

/* Buttons colors */
$button-color: #f2f2f2;
$button-accept: #46e08c;
$success-color: #27d6e6;
$success-hover-color: #00a4b3;
$destroy-color: #fb6d6d;
$destroy-hover-color: #fb6d6d;

/* Additional */
$background-white-color: #ffffff;
$background-blue: #e9fafc;
$black-color: #000000;
$grey-color: #999999;
$light-grey-color: #f6f7f9;
$background-light-color: #f0f0f1;
$red-color: #cd513e;
$red-button-color: #e02020;
$green-color: #6dd400;
$spaceti-color-dark: #14b8c7;

$blue-color: #1aafbc;
$orange-color: #f3aa1a;
$navy-color: #264dd2;
$dark-green-color: #1fc869;

$parking-image: '/assets/images/left-menu/parking.svg';
$common-image: '/assets/images/left-menu/common-space.svg';
$meeting-image: '/assets/images/left-menu/meeting-room.svg';
$workspace-image: '/assets/images/left-menu/workspace.svg';
$other-image: '/assets/images/general/dots.svg';

$stone-bg: '/assets/images/insights/stone-bg.svg';
$wellbeing-image: '/assets/images/insights/wellbeing.svg';
$temperature-image: '/assets/images/insights/temperature.svg';
$peoplecount-image: '/assets/images/insights/peoplecount.svg';
$brightness-image: '/assets/images/insights/brightness.svg';
$humidity-image: '/assets/images/insights/humidity.svg';
$co2-image: '/assets/images/insights/cloud-co-2.svg';
$energy-image: '/assets/images/insights/energy.svg';
$sound-image: '/assets/images/insights/sound.svg';
