.tabs {
  ul {
    border-bottom: 1px solid $second-color !important;
    margin-left: 25px;

    @media only screen and (max-width: 1024px) {
      margin-left: 10px;
    }
  }

  li {
    margin-top: 0 !important;

    a {
      color: $description-color;
    }
  }

  .is-active {
    border-bottom: 3px solid $spaceti-color;
    color: $brand-color !important;
  }
}

.admin-panel {
  padding-top: 80px;
}
