strong {
  font-weight: bold !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headings-color;
  font-family: 'opensans-bold', sans-serif !important;
  font-display: fallback;
  font-weight: 900;
  text-transform: initial;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

h1,
.h1 {
  font-size: 44px !important;
  line-height: 1.27 !important;

  @media only screen and (max-width: 960px) {
    font-size: 36px !important;
  }

  @media only screen and (max-width: 480px) {
    font-size: 27px !important;
  }
}

h2,
.h2 {
  font-size: 32px !important;
  line-height: 1.38 !important;

  @media only screen and (max-width: 480px) {
    font-size: 25px !important;
  }
}

h3,
.h3 {
  font-size: 24px !important;
  line-height: 1.33 !important;

  @media only screen and (max-width: 768px) {
    font-size: 17px !important;
  }
}

.h4 {
  font-size: 20px !important;
  line-height: 1.4 !important;
}

.h5 {
  font-size: 18px !important;
  line-height: 1.33 !important;
}

p,
a,
ul,
li,
span,
label,
input {
  color: $text-color;
  font-family: 'opensans-regular', sans-serif;
  font-display: fallback;
}

input,
.input,
.k-textbox {
  color: $input-color;
}

a {
  text-decoration: none;
  color: $main-color;
  transition: color 0.5s ease-out;
  -webkit-transition: color 0.5s ease-out;
  -moz-transition: color 0.5s ease-out;
  -o-transition: color 0.5s ease-out;
}

a:hover,
a:active,
a:focus {
  color: $link-hover-color;
  text-decoration: none;
}
