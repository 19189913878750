// -- vars
$bg-color: transparent;
$default-size: 1em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 5;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      -webkit-transform: rotate($progress * 3.6deg);
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      -webkit-transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      -webkit-transform: rotate((100 - $progress) / 100 * 360deg);
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

.set-size {
  font-size: 4em;
  padding: 10px 20px 0 20px;
}

.pie-wrapper {
  @include size($default-size, $default-size);
  position: relative;

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: -1vw;
    position: absolute;
    top: -20px;
    z-index: 9;

    .half-circle {
      @include size(100%, 100%);
      border: 2.8px solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: -20px;
    line-height: $label-font-size-redo * 0.7;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: -6px;

    img {
      max-height: 35px;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: 2.8px solid #ddd;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    left: -1vw;
  }

  &.progress-30 {
    @include draw-progress(30, #fff);
  }

  &.progress-45 {
    @include draw-progress(45, #fff);
  }

  &.progress-60 {
    @include draw-progress(60, #fff);
  }

  &.progress-75 {
    @include draw-progress(75, #fff);
  }

  &.progress-95 {
    @include draw-progress(95, #fff);
  }

  &.progress-100 {
    @include draw-progress(100, #fff);
  }
}

@media (max-width: 1333px) {
  .shadow,
  .pie {
    left: -1.5vw !important;
  }
}
